import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import ModernImg from "../../components/ModernImg"
import SinglePageHeader from "../../components/SinglePageLayout"

const mediaLink = [
  {
    title: "Ascott Acquires Serviced Apartment Brand Oakwood",
    url:
      "https://www.businesstraveller.com/business-travel/2022/07/04/ascott-acquires-serviced-apartment-brand-oakwood/",
  },
  {
    title:
      "Pair of Floors Sold as TE Capital, Lasalle Launch Cecil Street Office Tower in Singapore",
    url:
      "https://www.mingtiandi.com/sponsored/pair-of-floors-sold-as-te-capital-lasalle-launch-cecil-street-office-tower-in-singapore/",
  },
  {
    title:
      "ADDX sold stake in CYBAVO as part of Circle’s Q3 Acquisition of the Digital Asset Infrastructure-as-a-Service Business",
    url:
      "https://addx.co/files/ADDX_has_sold_stake_in_CYBAVO_as_part_of_Circles_acquisition_of_the_digital_asset_infrastructure_as_a_service_business_718bec369f.pdf",
  },
  {
    title: "Enabling fractional investing in private market assets",
    url:
      "https://www.theedgemarkets.com/article/cover-story-enabling-fractional-investing-private-market-assets",
  },
  {
    title: "SGX-backed ADDX expands in China",
    url: "https://www.hubbis.com/news/sgx-backed-addx-expands-in-china",
  },
  {
    title:
      "Blockchain capital markets platform iSTOX enters MAS fintech sandbox; sets up legal panel",
    url:
      "https://www.straitstimes.com/business/banking/blockchain-capital-markets-platform-istox-enters-mas-fintech-sandbox-sets-up-legal",
  },
  {
    title: "iSTOX Graduates from MAS Regulatory Sandbox",
    url:
      "https://www.securities.io/istox-graduates-from-mas-regulatory-sandbox/",
  },
  {
    title:
      "ICH Multi-Family Office unit becomes first Singapore Fund Manager to receive allocation under China's Scheme for Global Asset Managers",
    url:
      "https://www.businesstimes.com.sg/banking-finance/icham-is-first-singapore-fund-manager-to-receive-allocation-under-chinas-scheme-for",
  },
  {
    title:
      "Singapore-Chongqing financial connectivity strengthens amid the pandemic",
    url:
      "https://www.mas.gov.sg/news/media-releases/2021/singapore-chongqing-financial-connectivity-strengthens-amid-the-pandemic",
  },
  {
    title: "Chinese Investors to Gain Access to Digital Securities via ADDX",
    url:
      "https://www.regulationasia.com/chinese-investors-to-gain-access-to-digital-securities-via-addx/",
  },
  {
    title: "Singapore-Backed Securities Exchange Upbeat On China Prospects",
    url:
      "https://www.wealthbriefingasia.com/article.php?id=192289#.YTHQl50zaUk",
  },
]

export default function Media() {
  const { t } = useTranslation()
  const projects = [
    {
      title: t("qing-hai-project"),
      summary: t("qing-hai-subtitle"),
      icon: "",
      link: "/media/qing-hai-project/",
    },
  ]
  return (
    <SinglePageHeader title={t("media")} bg="/assets/img/media.jpg">
      <section className="ich-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-title section-title-2 text-center">
                <h2 className="title">{t("media")}</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <div>
                <ul style={{ listStyle: "square" }}>
                  {mediaLink.map((item, index) => (
                    <li key={index} style={{ marginBottom: 15 }}>
                      <Link
                        activeClassName="active"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "underline" }}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-grey ich-container" style={{ maxWidth: "none" }}>
        <div className="container" style={{ maxWidth: 960 }}>
          <div className="section-title section-title-2 text-center">
            <h2 className="title">{t("projects")}</h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12">
              {projects.map((item, index) => (
                <div className="col-lg-6 col-sm-6" key={item.link}>
                  <div className="single-industries-wrap">
                    <div className="media">
                      <div className="thumb">
                        <ModernImg
                          src={item.icon || "/assets/img/industries/3.png"}
                          alt={item.title}
                        />
                      </div>
                      <div className="single-industries-details media-body">
                        <h6>
                          <Link to={item.link}>{item.title}</Link>
                        </h6>
                        <p>{item.summary}</p>
                      </div>
                    </div>
                    <Link className="details-btn" to={item.link}>
                      {t("details-here")}
                      <span>
                        <i className="la la-arrow-right"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </SinglePageHeader>
  )
}
